import React from 'react';
import Modal from './Modal';
import Select from 'react-select';
import Checkbox from '../Checkbox';
import api from '../../api';
import { toast } from 'react-toastify';

// Generate a list of options - 2022 up to this year.
const options = [];
for (let i = 2022; i <= new Date().getFullYear(); i++) {
    options.push({
        value: i,
        label: i
    });
}

export default class RunTA extends Modal {
    state = {
		planYear: new Date().getFullYear(),
        rerun: false,
        allPlans: false
    }
    renderContent = () => {
        return (
            <div className="modal-content modal-center">
                <div
                style={{height: 150}}>
                <h1>Generate Trust Accounting Sheets</h1>
                <p>Run trust accounting for your plans and accounts based on the statements that have been processed.</p>
                
                <br/>
                
                <form style={{
                    width: '400px',
                    margin: 'auto'   
                }}
                    onSubmit={async(e) => {
                        this.props.loading(9999);
                        e.stopPropagation();
                        e.preventDefault();
                        await api.post('/tpa/ta/run', {
                            planYear: this.state.planYear,
                            rerun: this.state.rerun,
                            manual: true, 
                            // If allPlans is checked we need to pass an empty array to run for all plans else we pass the selected plan.
                            plans: this.state.allPlans ? [] : [this.props.data.internalPlanId]
                        });
                        this.props.loaded();
                        this.props.modal();
                        toast.info('Your trust accounting sheets are being generated. They will start filing to your plan directories automatically when ready.');
                    }}
                >

                    <label>Plan Year <span className='config-required'>(required)</span></label>
                    <Select
                        classNamePrefix="select"
                        options={options}
                        value={options.find(o => o.value === this.state.planYear)}
                        isSearchable={false}
                        placeholder="Select an option..."
                        onChange={(e) => {this.setState({planYear: e.value})}}
                    />
                    <div style={{ clear: 'both', height: 40 }} />

                    <Checkbox
                        label="Re-run trust accounting for all documents (even previously processed documents)"
                        checked={this.state.rerun}
                        onToggle={() => this.setState({rerun: !this.state.rerun})}
                    />

                    <Checkbox
                        label="Run trust accounting for all plans and not just the selected plan"
                        checked={this.state.allPlans}
                        onToggle={() => this.setState({allPlans: !this.state.allPlans})}
                    />

                    <input
                        type="submit" 
                        value="Submit"
                        className="modal-button-center"
                    />
                </form>
            </div>

            </div>
        )
    }

}